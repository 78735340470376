'use client';

import { useEffect } from 'react';
import { trackException } from '@reshima/telemetry';
import { ReshimaPage } from '@reshima/shared-ui';
import { Button } from '@reshima/pure-ui';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const name = 'Error';
  const { heading, description, tryAgain } = {
    heading: 'שגיאה',
    description: 'משהו השתבש, יש לנסות שוב',
    tryAgain: 'נסה שוב',
  };

  useEffect(() => {
    trackException({ name, action: name, error });
  }, [name, error]);

  return (
    <ReshimaPage name={name} heading={heading} description={description}>
      <Button onClick={reset} className="mx-auto">
        {tryAgain}
      </Button>
    </ReshimaPage>
  );
}
