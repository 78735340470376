import { PropsWithChildren, ReactNode } from 'react';
import { Heading } from '@reshima/pure-ui';
import { PageTelemetry } from './page-telemetry';
import { PreviousPageLinkProps, PreviousPageLink } from './previous-page-link';

type Props = PropsWithChildren<{
  name: string;
  heading?: ReactNode;
  left?: ReactNode;
  description?: string | string[];
  previousPage?: PreviousPageLinkProps;
}>;

function Descriptions({ description }: { description: Props['description'] }) {
  if (!description) return null;

  return (
    <div className="flex flex-col items-center text-center">
      {Array.isArray(description) ? (
        description.map((p, i) => <p key={i}>{p}</p>)
      ) : (
        <p>{description}</p>
      )}
    </div>
  );
}

export function ReshimaPage({
  name,
  heading,
  left,
  previousPage,
  description,
  children,
}: Props) {
  return (
    <div className="flex flex-col gap-3 w-full mx-auto">
      {name && <PageTelemetry name={name} />}
      {heading && (
        <div className="w-full max-w-sm mx-auto grid grid-cols-[1fr_auto_1fr] items-center">
          <div className="flex justify-start">
            {previousPage && <PreviousPageLink {...previousPage} />}
          </div>
          <Heading>{heading}</Heading>
          <div className="flex justify-end">{left}</div>
        </div>
      )}
      <Descriptions description={description} />
      {children}
    </div>
  );
}
